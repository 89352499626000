import axios from 'axios'
import publicRuntimeConfig from '../../public-runtime.config'
import addResponseInterceptors from './interceptors.response'
import addRequestInterceptors from './interceptors.request'

const axiosFactory = (baseURL) =>
  axios.create({
    baseURL,
    timeout: 30000,
  })

const coreAxios = axiosFactory(`${publicRuntimeConfig.core}/api`)
const solarSalesAxios = axiosFactory(`${publicRuntimeConfig.solarSales}/api/`)
const solarSalesPublicAxios = axiosFactory(
  `${publicRuntimeConfig.solarSales}/api/public/`
)
const solarOpsAxios = axiosFactory(`${publicRuntimeConfig.solarOps}/api/`)
const solarProductAxios = axiosFactory(
  `${publicRuntimeConfig.solarProduct}/api`
)

const requestInstances = [
  coreAxios,
  solarSalesAxios,
  solarOpsAxios,
  solarProductAxios,
]
const responseInstances = [
  coreAxios,
  solarSalesAxios,
  solarOpsAxios,
  solarProductAxios,
  solarSalesPublicAxios,
]

requestInstances.forEach(addRequestInterceptors)
responseInstances.forEach(addResponseInterceptors)

export {
  coreAxios,
  solarSalesAxios,
  solarOpsAxios,
  solarProductAxios,
  solarSalesPublicAxios,
}
