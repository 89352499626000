import { checkIfCupsIsValid } from '@modules/cups/application/check-if-cups-is-valid'
import { getPotentialByCups } from '@modules/potential/application/get-potential-by-cups'
import { getSupplyPointByCups } from '@modules/supply-point/application/get-supply-point-by-cups'
import { checkIsNewContractOrUpselling } from '@modules/electricity-contract/application/check-is-new-contract-or-upselling-by-cups'
import { getDealStage as getDealStageUseCase } from '@modules/deal/application/get-deal-stage'
import { updateAddressOnClips } from '@modules/update-address/application/update-address-on-clips'
import { sendDatadogError, sendDatadogEvent } from '@ui/plugins/datadog'

function validateCups({ commit, dispatch }, cups) {
  dispatch('resetInfoState')

  function onValidCups() {
    sendDatadogEvent({
      name: 'valid-cups',
      value: { cups },
    })
    commit('SET_CUPS', cups)
  }

  function onInvalidCups() {
    sendDatadogError({
      name: 'invalid-cups',
      options: { cups },
    })
    throw new Error('invalid-cups')
  }

  checkIfCupsIsValid().execute(
    {
      cups,
    },
    {
      onValidCups,
      onInvalidCups,
    }
  )
}
function updateCups({ commit }, cups) {
  commit('SET_CUPS', cups)
}

async function checkIfLeadIsNewContractOrUpselling({ commit }, cups) {
  function onUpselling(contractId) {
    commit('SET_ELECTRICITY_CONTRACT_ID', contractId)
  }

  function onNetworkError() {
    throw new Error('api-error')
  }

  await checkIsNewContractOrUpselling().execute(
    { cups },
    {
      onNewContract: () => {},
      onUpselling,
      onNetworkError,
    }
  )
}

async function requestClient({ commit, rootGetters }, cups) {
  const isExternalChannel = rootGetters['user/isExternalChannel']

  function onPotential(client) {
    commit('SET_CLIENT', client)
  }

  function onNetworkError() {
    throw new Error('api-error')
  }

  await getPotentialByCups().execute(
    { cups, isExternalChannel },
    {
      onPotential,
      onNoPotential: () => {},
      onExternalChannel: () => {},
      onNetworkError,
    }
  )
}

async function requestSupplyPoint({ commit }, cups) {
  function onSupplyPoint(supplyPoint) {
    commit('SET_SUPPLYPOINT', supplyPoint)
  }

  function onNetworkError() {
    throw new Error('api-error')
  }

  await getSupplyPointByCups().execute(
    { cups },
    {
      onSupplyPoint,
      onNetworkError,
    }
  )
}

function resetInfoState({ commit }) {
  commit('RESET_STATE')
}

async function getDealStage({ commit }, cups) {
  commit('SET_DEAL_STAGE', '')
  commit('SET_INSTALLATION_STAGE', '')

  function onOfferStage(status, stage = '') {
    commit('SET_DEAL_STAGE', status)
    if (stage) {
      commit('SET_INSTALLATION_STAGE', stage)
    }
  }

  await getDealStageUseCase().execute(
    { cups },
    {
      onOfferStage,
      onError: () => {},
    }
  )
}

async function requestUpdateAddressOnClips(
  { state, commit },
  { postalCode, city, address, province }
) {
  function onAddressUpdated() {
    const dataAddress = {
      address,
      city,
      postalCode,
      province,
    }
    sendDatadogEvent({
      name: 'modify-address',
      value: { cups: state.cups, ...dataAddress },
    })
    commit('SET_SUPPLYPOINT_ADDRESS', dataAddress)
  }
  function onNetworkError(message) {
    sendDatadogError({
      name: 'modify-address-error',
      options: { cups: state.cups, message },
    })
    throw new Error(message)
  }

  await updateAddressOnClips().execute(
    {
      cups: state.cups,
      postalCode,
      locality: city,
      street: address,
    },
    { onAddressUpdated, onNetworkError }
  )
}

function setOverwrittenCups({ commit }, overwritten) {
  commit('SET_OVERWRITTEN_CUPS', overwritten)
}

export default {
  validateCups,
  updateCups,
  checkIfLeadIsNewContractOrUpselling,
  requestClient,
  requestSupplyPoint,
  resetInfoState,
  getDealStage,
  requestUpdateAddressOnClips,
  setOverwrittenCups,
}
