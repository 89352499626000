const gimletStagingApigatewayBase = 'https://gimlet.staging.holaluz.com/api'
const gimletProdApigatewayBase = 'https://gimlet.holaluz.com/api'

const urlsForInternalUse = {
  core: 'https://core-staging.holaluz.com',
  bonasera: 'https://sales.staging.holaluz.com',
  solarSales: 'https://solarsales.staging.holaluz.com',
  barman: 'https://auth.staging.holaluz.com',
  assets: 'https://assets.holaluz.com',
  solarOps: 'https://solarops.staging.holaluz.com',
  solarProduct: 'https://solarproduct.staging.holaluz.com',
}

const publicRuntimeConfigs = {
  production: {
    core: `${gimletProdApigatewayBase}/core`,
    bonasera: 'https://sales.holaluz.com',
    solarSales: 'https://solarsales.holaluz.com',
    barman: 'https://auth.holaluz.com',
    assets: 'https://assets.holaluz.com',
    solarOps: 'https://solarops.holaluz.com',
    solarProduct: 'https://solarproduct.holaluz.com',
    hasDatadog: true,
    datadogEnv: 'prod',
    googleGeocodingApikey: 'AIzaSyB_Z5tr3tAffQc_FNzR4s-jIDlmi-CUKJ0',
    assetsUrl: 'https://assets.holaluz.com',
    configCatApiKey: 'u-TaCJhXWUqMMeDhFXtF1Q/pYn2D7dokUaxeQ0MXYOB7A',
  },
  staging: {
    ...urlsForInternalUse,
    core: `${gimletStagingApigatewayBase}/core`,
    hasDatadog: false,
    datadogEnv: 'staging',
    googleGeocodingApikey: 'AIzaSyB_Z5tr3tAffQc_FNzR4s-jIDlmi-CUKJ0',
    configCatApiKey: 'u-TaCJhXWUqMMeDhFXtF1Q/YpreY3-c6kihSm82XRBI_w',
    assetsUrl: 'https://assets.holaluz.com',
  },
  development: {
    ...urlsForInternalUse,
    core: `${gimletStagingApigatewayBase}/core`,
    hasDatadog: false,
    datadogEnv: 'staging',
    googleGeocodingApikey: 'AIzaSyCBSv09-TUmyCFPBKnwkqden1zGq_ftJrA',
    configCatApiKey: 'u-TaCJhXWUqMMeDhFXtF1Q/yl1GxywK6Ee1dkgmJ48MfQ',
    assetsUrl: 'https://assets.holaluz.com',
  },
  qa: {
    ...urlsForInternalUse,
    core: `${gimletStagingApigatewayBase}/core`,
    hasDatadog: false,
    datadogEnv: 'staging',
    googleGeocodingApikey: 'AIzaSyCBSv09-TUmyCFPBKnwkqden1zGq_ftJrA',
    assetsUrl: 'https://assets.holaluz.com',
  },
}

type Env = 'production' | 'staging' | 'development' | 'qa'
const env = (process.env.ENV as Env) || 'development'

const modes = {
  isStaging: env === 'staging',
  isDevelopment: env === 'development',
  isProduction: env === 'production',
  isQa: env === 'qa',
}

const publicRuntimeConfig = publicRuntimeConfigs[env]

export default { ...publicRuntimeConfig, ...modes, env }
