export default {
  userPermissions: (_, __, { auth }) => {
    return auth?.user?.roles?.length ? auth.user.roles : []
  },

  salesCompanyChannel: (_, __, { auth }) =>
    auth?.user?.salesCompanyChannel || null,

  salesCompanyId: (_, __, { auth }) => auth?.user?.salesCompany || null,

  isExternalChannel: (state, { salesCompanyChannel }) => {
    if (!salesCompanyChannel) return false
    return salesCompanyChannel.includes(
      state.salesCompanyChannels.REPRESENTATIVE
    )
  },

  isVisitor: (_, { userPermissions }) =>
    userPermissions.includes('ROLE_SOLAR_VISITOR'),

  userType: (_, { isVisitor, isExternalChannel }) => {
    const user = {
      channel: isExternalChannel,
      visitor: isVisitor,
    }
    return Object.keys(user).find((i) => user[i]) || 'visitor'
  },

  username: (_, __, { auth }) => auth?.user?.username,

  userRole: (_, __, { auth }) => auth?.user?.type,
}
