import { dealApiService } from '@modules/deal/infrastructure/deal.api.service'
import {
  DEAL_STAGE,
  InstallationStageKeys,
  MAPPED_STAGE,
  MAPPED_INSTALLATION_STAGE,
} from '@modules/deal/domain/stage.types'

type Callbacks = {
  onOfferStage: (
    status: DEAL_STAGE,
    stage?: (typeof MAPPED_INSTALLATION_STAGE)[InstallationStageKeys]
  ) => void
  onError: () => void
}

type Parameters = {
  cups: string
}

export function getDealStage() {
  return { execute }

  async function execute(
    { cups }: Parameters,
    { onOfferStage, onError }: Callbacks
  ) {
    try {
      const stringStage = await dealApiService.getSolarStage(cups)

      const stage = stringStage as keyof typeof MAPPED_STAGE
      const installationStage =
        stringStage as keyof typeof MAPPED_INSTALLATION_STAGE
      onOfferStage(
        MAPPED_STAGE[stage],
        MAPPED_INSTALLATION_STAGE[installationStage]
      )
    } catch (error) {
      if (error.response?.status !== 404) {
        onError()
      }
    }
  }
}
