import { handleGetOfferSpecs } from '@application/OfferSpecs/getOfferSpecs'
import { handleGetContract } from '@application/Contract/getContract'
import { handleUpsertOfferSpecs } from '@application/OfferSpecs/upsertOfferSpecs'
import { handleSaveVisitCheck } from '@modules/visit-check/application/save-visit-check'
import { handleSaveVisitInterest } from '@modules/visit-interest/application/save-visit-interest'
import { handleGetRadiation } from '@application/Radiation/getRadiation'
import { handleGetOffer } from '@application/Offer/getOffer'
import { handleRecoverOffer } from '@application/Offer/recoverOffer'
import { handleGetValidOffer } from '@application/Offer/getValidOffer'
import { handleCreateLeadByEmail } from '@application/Lead/createLeadByEmail'
import { handleGetFinancingConditions } from '@application/Financing/getFinancingConditions'
import { handleCalculateFinancingData } from '@application/Financing/calculateFinancingData'
import { downloadIndirectSolarOfferPdf } from '@modules/pdf/application/download-indirect-solar-offer-pdf'
import { handleIsOfferable } from '@modules/is-offerable/application/is-offerable'
import { handleSetKo } from '@modules/deal/application/set-ko'
import { handlerSimulatedVerifiedOffer } from '@modules/simulated-offer/application/get-simulated-verified-offer'
import { getAssetsCatalog } from '@modules/assets-catalog/application/get-assets-catalog'
import { handleUnbindContract } from '@modules/contract/application/unbind'
import { sendDatadogError, sendDatadogEvent } from '@ui/plugins/datadog'

async function requestOfferSpecs({ commit }, cups) {
  function respondWithSpecs(specs) {
    sendDatadogEvent({
      name: 'offer-specs',
      value: { cups, ...specs },
    })
    commit('SET_SPECS_RESULT', null)
    commit('SET_SPECS', specs)
  }

  function respondWithNoOffer() {
    sendDatadogError({
      name: 'offer-specs-error',
      options: {
        cups,
        errorType: 'no-offer',
      },
    })
    commit('SET_SPECS_RESULT', 'no-offer')
    commit('SET_SPECS', null)
  }

  function respondWithError() {
    sendDatadogError({
      name: 'offer-specs-error',
      options: {
        cups,
        errorType: 'api-error',
      },
    })
    commit('SET_SPECS_RESULT', null)
    throw new Error('api-error')
  }

  await handleGetOfferSpecs({
    respondWithSpecs,
    respondWithNoOffer,
    respondWithError,
  }).execute(cups)
}

async function requestContract({ commit }, cups) {
  function respondWithContract(contract) {
    sendDatadogEvent({
      name: 'get-contract',
      value: { cups, contract },
    })
    commit('SET_CONTRACT', contract)
  }

  function respondWithError() {
    sendDatadogError({
      name: 'get-contract-error',
      options: {
        cups,
      },
    })
    commit('SET_CONTRACT_ERROR', 'error')
  }

  commit('SET_CONTRACT', null)
  commit('SET_CONTRACT_ERROR', null)

  await handleGetContract({ respondWithContract, respondWithError }).execute(
    cups
  )
}

function updateOfferSpecs({ commit, dispatch }, value) {
  commit('SET_SPECS', value)
  if (!('promotions' in value) && !('discounts' in value)) {
    dispatch('hideSimulationOffer', true)
  }
}

async function updateVisitCheck({ state }) {
  const respondWithSuccess = () => {
    sendDatadogEvent({
      name: 'update-visit-check',
      value: { cups: state.specs.cups },
    })
  }

  const respondWithError = () => {
    sendDatadogError({
      name: 'update-visit-check-error',
      options: { ...dataInfo, errorType: 'api-error' },
    })
    throw new Error('api-error')
  }

  await handleSaveVisitCheck().execute(state.specs.cups, {
    respondWithSuccess,
    respondWithError,
  })
}

async function updateVisitInterest({ state }, visitInterest) {
  const dataInfo = { cups: state.specs.cups, interest: visitInterest }

  const respondWithSuccess = () => {
    sendDatadogEvent({
      name: 'update-visit-interest',
      value: { ...dataInfo, ...state.specs },
    })
  }

  const respondWithError = () => {
    sendDatadogError({
      name: 'update-visit-interest-error',
      options: { ...dataInfo, ...state.specs, errorType: 'api-error' },
    })
    throw new Error('api-error')
  }

  await handleSaveVisitInterest().execute(dataInfo, {
    respondWithSuccess,
    respondWithError,
  })
}

function resetOfferOnVisitState({ commit }) {
  commit('RESET_STATE')
}

function setSpecsFormValidation({ commit }, value) {
  commit('SET_SPECS_FORM_VALIDATION', value)
}

function setSpecsAsSubmitted({ commit }) {
  commit('SET_SPECS_SUBMITTED', true)
}

async function upsertOfferSpecs({ commit, state }) {
  commit('SET_SPECS_ERROR', null)

  const respondWithSuccess = (specs) => {
    sendDatadogEvent({
      name: 'upsert-offer',
      value: { ...specs },
    })
    commit('SET_SPECS', specs)
    this.$router.push('/offer/final-offer')
  }

  const respondWithError = (error) => {
    sendDatadogError({
      name: 'upsert-offer-error',
      options: { ...state.specs, error },
    })
    commit('SET_SPECS_ERROR', error)
  }

  await handleUpsertOfferSpecs({
    respondWithSuccess,
    respondWithError,
  }).execute(state.specs)
}

async function getRadiation({ commit, state }, cover) {
  const isCover1 = cover === 'cover1'
  const radiation = isCover1 ? 'radiation1' : 'radiation2'
  const payload = {
    latitude: state.specs.coordinates.latitude,
    longitude: state.specs.coordinates.longitude,
    azimuth: isCover1 ? state.specs.azimuth1 : state.specs.azimuth2,
    tilt: isCover1 ? state.specs.tilt1 : state.specs.tilt2,
  }

  function respondWithSuccess(specs) {
    commit('SET_SPECS', specs)
  }

  function respondWithError() {
    commit('SET_RADIATION_ERROR', cover)
    commit('SET_SPECS', { [radiation]: '' })
  }

  await handleGetRadiation({
    respondWithSuccess,
    respondWithError,
  }).execute(radiation, payload)
}

async function getOffer({ commit, getters, state, dispatch, rootState }) {
  commit('SET_OFFERS', null)
  commit('SET_TAX_TYPE', '')
  commit('SET_INSTALLATION_TAX', '')
  dispatch('cleanApiError')
  commit('SET_DUE_DATE', '')
  commit('SET_IS_VERIFIED', '')

  const dataInfo = {
    cups: rootState.info.cups,
    currentInvoice: state.specs.currentInvoice,
    clientType: state.clientType,
    hasDoneEnergyReform: state.hasDoneEnergyReform,
  }

  const respondWithOffer = (offer) => {
    sendDatadogEvent({
      name: 'get-offer',
      value: { ...dataInfo, ...offer },
    })
    commit('SET_OFFERS', offer.defaultOffers)
    commit('SET_TAX_TYPE', offer.installation.taxType)
    commit('SET_INSTALLATION_TAX', offer.installation.tax)
    commit('SET_DUE_DATE', offer.installation.dueDate)
    commit('SET_IS_VERIFIED', offer.installation.isVerified)

    // [Standard VS Recommended] Fix to show recommended offer instead of standard for final offers
    if (state.isVerified) {
      commit('SET_SELECTED_OFFER', 'recommended')
    }

    const selectedOffer = getters.offer
    commit(
      'SET_SELECTED_FINANCING_CONDITION',
      selectedOffer.financingData?.financingConditionsId || 'cash'
    )
  }

  const respondWithError = (error) => {
    sendDatadogError({
      name: 'get-offer-error',
      options: { ...dataInfo, error },
    })
    if (rootState.info.cups) {
      throw new Error(error)
    }
  }
  await handleGetOffer({
    respondWithOffer,
    respondWithError,
  }).execute({
    ...dataInfo,
  })
}

async function recoverOffer({ commit, getters, dispatch }, cups) {
  commit('SET_OFFERS', null)
  commit('SET_TAX_TYPE', '')
  commit('SET_INSTALLATION_TAX', '')
  dispatch('cleanApiError')
  commit('SET_DUE_DATE', '')
  commit('SET_IS_VERIFIED', '')

  const respondWithOffer = (offer) => {
    sendDatadogEvent({
      name: 'recover-offer',
      value: { cups, ...offer },
    })
    commit('SET_OFFERS', offer.defaultOffers)
    commit('SET_TAX_TYPE', offer.installation.taxType)
    commit('SET_INSTALLATION_TAX', offer.installation.tax)
    commit('SET_DUE_DATE', offer.installation.dueDate)
    commit('SET_IS_VERIFIED', offer.installation.isVerified)
    commit('SET_CLIENT_TYPE', offer.installation.clientType)
    commit('SET_ENERGY_REFORM', offer.installation.hasDoneEnergyReform)

    const selectedOffer = getters.offer
    commit(
      'SET_SELECTED_FINANCING_CONDITION',
      selectedOffer.financingData?.financingConditionsId || 'cash'
    )
  }

  const respondWithError = (error) => {
    sendDatadogError({
      name: 'recover-offer-error',
      options: {
        cups,
        error,
        errorType: 'api-error',
      },
    })
    throw new Error(error)
  }

  const respondWithNoOffer = () => {
    sendDatadogError({
      name: 'recover-offer-error',
      options: {
        cups,
        errorType: 'no-offer',
      },
    })
    commit('SET_SPECS_RESULT', 'no-offer')
  }

  await handleRecoverOffer({
    respondWithOffer,
    respondWithNoOffer,
    respondWithError,
  }).execute({
    cups,
  })
}

async function requestAssetsCatalog({ rootState, state, commit }) {
  commit('SET_ASSETS_CATALOG_LOADING', true)
  commit('SET_ASSETS_CATALOG_ERROR', false)

  const dataInfo = {
    postalCode: rootState.info.supplyPoint.address.postalCode,
    installationType: state.specs.installationType,
  }

  const onAssetsCatalog = (assets) => {
    sendDatadogEvent({
      name: 'assets-catalog',
      value: { ...dataInfo, assets },
    })
    commit('SET_ASSETS_CATALOG', assets)
    commit('SET_ASSETS_CATALOG_LOADING', false)
  }
  const onError = (error) => {
    sendDatadogError({
      name: 'assets-catalog-error',
      options: { ...dataInfo, error },
    })
    commit('SET_ASSETS_CATALOG', [])
    commit('SET_ASSETS_CATALOG_LOADING', false)
    commit('SET_ASSETS_CATALOG_ERROR', true)
    // eslint-disable-next-line no-console
    console.error(error)
  }

  try {
    await getAssetsCatalog().execute(
      {
        ...dataInfo,
      },
      {
        onAssetsCatalog,
        onNetworkError: onError,
      }
    )
  } catch (error) {
    onError(error)
  }
}

function setSelectedOffer({ commit }, selectedOffer) {
  commit('SET_SELECTED_OFFER', selectedOffer)
}

const setActiveOfferContext = ({ commit }, activeOfferContext) => {
  commit('SET_ACTIVE_OFFER_CONTEXT', activeOfferContext)
}

async function sendOfferEmail(
  { state, getters, rootState, rootGetters },
  { email, phone, name, language }
) {
  const leadInfo = {
    cups: rootState.info.cups,
    email,
    phone,
    name,
    language,
    address: rootGetters['info/fullAddress'],
    fee: state.specs.currentInvoice,
    paymentMethod: 'Postpago',
    product: rootState.info.client.electricityProduct.name,
    productId: rootState.info.client.electricityProduct.id,
    powerByPeriod: rootState.info.supplyPoint.power.optimizedByPeriod,
    isFinanced: getters.isFinanced,
    offer: getters.offer,
    installationTax: state.installationTax,
    financingConditionsId: state.selectedFinancingCondition,
    solarOfferId: getters.offer.id,
  }

  await handleCreateLeadByEmail({
    respondWithError: (error) => {
      sendDatadogError({
        name: 'create-lead-error',
        options: { leadInfo, error },
      })
      throw error
    },

    respondWithSuccess: () => {
      sendDatadogEvent({
        name: 'create-lead',
        value: { leadInfo },
      })
    },
  }).execute(leadInfo)
}

async function getFinancingConditions({ commit }) {
  function respondWithSuccess(financingConditions) {
    commit('SET_FINANCING_CONDITIONS', financingConditions)
  }

  function respondWithError() {
    // To be reviewed
  }

  await handleGetFinancingConditions({
    respondWithSuccess,
    respondWithError,
  }).execute()
}

async function setSelectedFinancingCondition(
  { commit, dispatch, getters },
  selectedFinancingCondition
) {
  commit('SET_SELECTED_FINANCING_CONDITION', selectedFinancingCondition)
  commit('UPDATE_OFFER_FINANCING_DATA', {})

  const { maxLoanYears } = getters.financingCondition
  const { offer } = getters

  commit('UPDATE_OFFER_FINANCING_DATA', {
    ...offer.financingData,
    loanYears: maxLoanYears,
  })

  const initialInvestment =
    selectedFinancingCondition === 'cash' ? offer.totalValue : 0
  commit('UPDATE_OFFER', { ...offer, initialInvestment })

  await dispatch('calculateFinancingData')
}

function updateFinancingData({ commit, getters, dispatch }, value) {
  const { offer, financingCondition } = getters

  handleGetValidOffer(respondWithValidOffer).execute({
    originalOffer: offer,
    value,
    financingCondition,
  })

  async function respondWithValidOffer({ offer }) {
    commit('UPDATE_OFFER', offer)

    await dispatch('calculateFinancingData')
  }
}

async function calculateFinancingData({ state, getters, commit }) {
  const { offer } = getters
  const { maxLoanYears } = getters.financingCondition

  const respondWithFinancingData = (financingData) => {
    commit('UPDATE_OFFER_FINANCING_DATA', financingData)
  }

  const respondWithError = () => {
    // TBD
  }

  await handleCalculateFinancingData({
    respondWithFinancingData,
    respondWithError,
  }).execute({
    installationTax: state.installationTax,
    grossCustomerSavingsWithTaxes: offer.grossCustomerSavingsWithTaxes,
    initialInvestment: offer.initialInvestment,
    loanYears: offer.financingData?.loanYears || maxLoanYears,
    capex: offer.capex,
    totalValue: offer.totalValue,
    financingConditionsId: state.selectedFinancingCondition,
  })
}

async function downloadIndirectOfferPdf(
  { commit, getters, rootGetters, rootState },
  nameAndSurname
) {
  commit('SET_DOWNLOAD_INDIRECT_PDF_LOADING', true)
  commit('SET_DOWNLOAD_INDIRECT_PDF_ERROR', false)
  commit('SHOW_DOWNLOAD_INDIRECT_ALERT', false)

  const { user } = rootState.auth
  const { technicalInfo } = rootState.info.supplyPoint

  function onOfferPdfUrl(fileUrl) {
    commit('SET_DOWNLOAD_INDIRECT_PDF_LOADING', false)
    commit('SHOW_DOWNLOAD_INDIRECT_ALERT', true)
    window.open(fileUrl)
  }

  function onDownloadPdfError() {
    commit('SET_DOWNLOAD_INDIRECT_PDF_LOADING', false)
    commit('SET_DOWNLOAD_INDIRECT_PDF_ERROR', true)
    commit('SHOW_DOWNLOAD_INDIRECT_ALERT', true)
  }

  await downloadIndirectSolarOfferPdf().execute(
    {
      offerId: getters.offer.id,
      ...nameAndSurname,
      address: rootGetters['info/fullAddress'],
      salesUserId: user.id,
      salesUserChannel: user.salesCompanyChannel,
      salesUserName: user.username,
      tariff: technicalInfo.tariff.id,
    },
    {
      onOfferPdfUrl,
      onDownloadPdfError,
    }
  )
}

function clearIndirectOfferPdf({ commit }) {
  commit('CLEAR_INDIRECT_OFFER_PDF')
}

async function requestIsOfferable({ commit }, cups) {
  commit('CLEAR_IS_OFFERABLE')

  function respondWithisOfferable() {
    sendDatadogEvent({
      name: 'is-offertable',
      value: { cups },
    })
    commit('SET_IS_OFFERABLE', true)
  }

  function respondWithNoOfferable() {
    sendDatadogError({
      name: 'is-offertable-error',
      options: {
        cups,
        errorType: 'no-offer',
      },
    })
    commit('SET_IS_OFFERABLE', false)
  }

  function respondWithError() {
    sendDatadogError({
      name: 'is-offertable-error',
      options: {
        cups,
        errorType: 'api-error',
      },
    })
    commit('SET_IS_OFFERABLE', false)
    commit('SET_IS_OFFERABLE_ERROR', true)
  }

  await handleIsOfferable().execute(cups, {
    respondWithisOfferable,
    respondWithNoOfferable,
    respondWithError,
  })
}

async function requestSetKo({ commit, rootState }, reason) {
  const cups = rootState.info.cups

  function respondWithSuccess() {
    sendDatadogEvent({
      name: 'ko-offer',
      value: { cups, reason },
    })
    commit('info/SET_DEAL_STAGE', 'ko', { root: true })
  }

  function respondWithError(error) {
    sendDatadogError({
      name: 'ko-offer-error',
      options: {
        cups,
        reason,
        error,
      },
    })
    throw error
  }

  await handleSetKo().execute(cups, reason, {
    respondWithSuccess,
    respondWithError,
  })
}

async function requestUnbindContract({ commit, rootState }) {
  const cups = rootState.info.cups
  const offerStatus = rootState.info.offerStatus

  function respondWithSuccess() {
    sendDatadogEvent({
      name: 'unbind-contract',
      value: { cups, offerStatus },
    })
    commit('info/SET_DEAL_STAGE', 'ko', { root: true })
  }

  function respondWithError(error) {
    sendDatadogError({
      name: 'unbind-contract-error',
      options: { cups, offerStatus },
    })
    throw error
  }

  await handleUnbindContract().execute(cups, {
    respondWithSuccess,
    respondWithError,
  })
}

async function handleGetSimulatedVerifiedOffer({
  state,
  commit,
  getters,
  dispatch,
}) {
  const { specs } = state

  if (getters.specFormHasErrors) return

  commit('SET_IS_SIMULATED_OFFER_LOADING', true)

  commit('SET_OFFER_ERROR', null)

  function onSimulatedVerifiedOffer(simulatedOffer) {
    sendDatadogEvent({
      name: 'simulate-verified',
      value: { ...specs, simulatedOffer },
    })
    commit('UPDATE_CUSTOM_SOLAR_OFFER', simulatedOffer)
    dispatch('updateOfferSpecs', { discounts: simulatedOffer.discounts })
  }

  function onError(error) {
    sendDatadogError({
      name: 'simulate-verified-error',
      options: {
        ...specs,
        error,
      },
    })
    commit('SET_OFFER_ERROR', error)
  }

  await handlerSimulatedVerifiedOffer().execute(specs, {
    onSimulatedVerifiedOffer,
    onError,
  })

  commit('SET_IS_SIMULATED_OFFER_LOADING', false)
}

function clearSimulatedOffer({ commit }) {
  commit('CLEAR_SIMULATED_OFFER')
}

async function loadDashboardInfo({ state, dispatch }, cups) {
  await Promise.all([
    dispatch('info/checkIfLeadIsNewContractOrUpselling', cups, { root: true }),
    dispatch('requestOfferSpecs', cups),
    dispatch('requestContract', cups),
    dispatch('info/requestClient', cups, { root: true }),
    dispatch('info/requestSupplyPoint', cups, { root: true }),
  ])

  if (state.specsResult && state.contractError) {
    throw new Error(state.specsResult)
  }

  await dispatch('recoverOffer', cups)
  await dispatch('info/getDealStage', cups, { root: true })

  if (state.specsResult && state.contractError) {
    throw new Error(state.specsResult)
  }
}

function hideSimulationOffer({ commit }, value) {
  commit('SET_HIDE_SIMULATION_OFFER', value)
}

function cleanApiError({ commit }) {
  commit('SET_OFFER_ERROR', null)
}

export default {
  requestOfferSpecs,
  requestContract,
  updateVisitCheck,
  updateVisitInterest,
  updateOfferSpecs,
  resetOfferOnVisitState,
  setSpecsFormValidation,
  upsertOfferSpecs,
  getRadiation,
  getOffer,
  recoverOffer,
  requestAssetsCatalog,
  setSelectedOffer,
  setActiveOfferContext,
  sendOfferEmail,
  getFinancingConditions,
  setSelectedFinancingCondition,
  updateFinancingData,
  calculateFinancingData,
  downloadIndirectOfferPdf,
  clearIndirectOfferPdf,
  requestIsOfferable,
  requestSetKo,
  requestUnbindContract,
  handleGetSimulatedVerifiedOffer,
  clearSimulatedOffer,
  setSpecsAsSubmitted,
  loadDashboardInfo,
  hideSimulationOffer,
  cleanApiError,
}
