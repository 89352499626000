import FinancingService from '@infrastructure/financing.api'

export function handleCalculateFinancingData({
  respondWithFinancingData,
  respondWithError,
}) {
  return { execute }

  async function execute(payload) {
    if (payload.financingConditionsId === 'cash') {
      respondWithFinancingData({})
    } else {
      try {
        const financingData =
          await FinancingService.calculateFinancingData(payload)

        respondWithFinancingData(financingData)
      } catch (error) {
        respondWithError()
      }
    }
  }
}
