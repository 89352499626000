import { rest } from 'msw'
import response from './response'

export const handlers = [
  rest.post(/\/.*\/offers\/.*\/contract/, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json(response.client))
  }),
  rest.get(/\/.*\/clients\/person-id\/.*/, (_req, res, ctx) => {
    return res(ctx.status(404), ctx.json(response.client))
  }),
  rest.get(/\/.*\/public\/offer\/.*/, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json(response.publicOfferData))
  }),
  rest.get(/\/.*\/offers\/ready-to-contract/, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json(response.verifiedOffers))
  }),
  rest.get(/\/.*\/offer-data/, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json(response.offerData))
  }),
  rest.get(/\/offer\/calculate-financing-data/, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json(response.financingData))
  }),
  rest.get(/\/.*\/offer\/final-offer/, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json(response.finalOffer))
  }),
  rest.get(/\/private\/.*\/offer/, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json(response.offer))
  }),
  rest.get(/\/.*\/recoveroffer/, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json(response.offer))
  }),
  rest.get(/\/.*\/assets-catalog\/batteries/, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json(response.batteries))
  }),
  rest.get(/\/.*\/assets-catalog\/ev-chargers/, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json(response.evChargers))
  }),
  rest.get(/\/holder/, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json(response.holder))
  }),
  rest.get(/\/private\/potentials/, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json(response.potentials))
  }),
  rest.get(/\/radiation/, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json(response.radiation))
  }),
  rest.get(/\/private\/supply-point/, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json(response.supplyPoint))
  }),
  rest.post(/upsert-verified-offer-data/, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}))
  }),
  rest.post(/\/leads\/email/, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}))
  }),
  rest.put(/\/offer/, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}))
  }),
  rest.get(/\/financing-conditions/, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json(response.financingConditions))
  }),
  rest.get(/\/offer\/last\/(.*?)\/pdf/, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}))
  }),
  rest.get(/\/.*\/deal/, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json(response.solarStage))
  }),
  rest.post(/\/.*\/deal\/ko/, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}))
  }),
  rest.get(/\/cups\/.*\/is-offerable/, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.delay(2000), ctx.json(response.isOfferable))
  }),
  rest.get(/\/contract\/.*\/current/, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json(response.currentContract))
  }),
  rest.get(/\/contract\/.*\/current/, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json(response.currentContract))
  }),
  rest.get(/\/.*\/contract-status/, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json(response.contractData))
  }),
  rest.post(/\/.*\/contract\/manual-signature/, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}))
  }),
  rest.post(/\/.*\/visit\/check/, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}))
  }),
  rest.post(/\/.*\/deal\/ko/, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}))
  }),
  rest.post(/\/.*\/interest/, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}))
  }),
  rest.post(/\/.*\/simulate-verified/, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json(response.simulateVerifiedOffer))
  }),
  rest.get(/\/.*\/contract\/unbind/, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}))
  }),
  rest.get(/\/.*\/core\/api\/contracts/, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.text(true))
  }),
  rest.patch(/\/.*\/deal\/.*/, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}))
  }),
]
