import OfferSpecsService from '@infrastructure/offer-specs.api'

export function handleGetOfferSpecs({
  respondWithSpecs,
  respondWithNoOffer,
  respondWithError,
}) {
  return { execute }

  async function execute(cups) {
    try {
      const specs = await OfferSpecsService.getOfferSpecs(cups)

      respondWithSpecs(specs)
    } catch (error) {
      if (error.response.status === 404) {
        respondWithNoOffer()
        return
      }
      respondWithError()
    }
  }
}
